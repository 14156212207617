// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Home from './Home';
import About from './About';
import App2 from './App2';
import PaymentForm from './PaymentForm';
import WeChatPay from './WeChatPay';
import PaymentDetector from './PaymentDetector';

function App() {
  return (
    <Router>
      <div>
        <label style={{ margin: '10px',color:"red"}}>debug</label>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link to="/App2">App2</Link>
            </li>

            <li>
              <Link to="/PaymentForm">PaymentForm</Link>
            </li>

            <li>
              <Link to="/WeChatPay">WeChatPay</Link>
            </li>

            <li>
              <Link to="/PaymentDetector">PaymentDetector</Link>
            </li>
          </ul>
        </nav>

        <hr />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/app2" element={<App2 />} />
          <Route path="/PaymentForm" element={<PaymentForm />} />
          <Route path="/WeChatPay" element={<WeChatPay />} />
          <Route path="/PaymentDetector" element={<PaymentDetector />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
