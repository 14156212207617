// PaymentForm.js
import React, { useState } from 'react';
import { Input, Button, Modal } from 'antd';
import { ThreeDots } from 'react-loader-spinner';

const PaymentForm = ({ onConfirm }) => {

    const [amount, setAmount] = useState('');
    const [responseData, setResponseData] = useState();
    const [showConfirmButton, setShowConfirmButton] = useState(true);
    const [showPayButton, setShowPayButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
        setError('');
    };

    const handleConfirmClick = () => {


    };

    const jsapiClick = () => {

    }

    // debug
    const handleDebugClick = () => {

        const { timeStamp, package: packageValue, paySign, appid, signType, nonceStr } = responseData;

        //  alert('debug'+JSON.stringify(responseData));
        //  alert('debug'+responseData.data.appid);

        // 发送orderid，获取wx.chooseWXPay和wx.config所需的参数

        const paymentData = {
            appId: responseData.data.appid,
            timeStamp: responseData.data.timeStamp,
            nonceStr: responseData.data.nonceStr,
            package: responseData.data.package,
            signType: responseData.data.signType,
            paySign: responseData.data.paySign,
        };


        // 使用微信 JSAPI 进行支付
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', paymentData, function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
                // 支付成功的逻辑
                alert('Payment successful!');
            } else {
                // 支付失败的逻辑
                alert('Payment failed: ' + res.err_msg);
            }
        });

    };


    // 获取支付参数
    const handleGetPayParamsClick = () => {
        if (!amount || parseFloat(amount) <= 0) {
            setError('Please enter a valid amount.');
            return;
        }

        setLoading(true);
        // alert('amount:' + amount);
        const apiUrl = 'https://tomatopayment.com/api/pay/js_api_pay';
        const requestBody = {
            code: getCodeFromUrl(),
            // amount: 10,
            amount: parseFloat(amount) * 100,
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Response data:', data);
                // alert('data:' + JSON.stringify(data));
                setResponseData(data);
                setShowConfirmButton(false);
                setShowPayButton(true);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                alert('There was a problem with the fetch operation: ' + error);
            })
            .finally(() => {
                setLoading(false);
            })

    }

    // 获取授权码code
    function getCodeFromUrl() {
        var url = window.location.search;
        var theRequest = new Object();
        if (url.indexOf("?") !== -1) {
            var str = url.substr(url.indexOf("?") + 1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
        }
        return theRequest.code || null;
    }




    return (
        <div style={{ maxWidth: '300px', margin: 'auto', padding: '20px' }}>
            <h2 style={{ textAlign: 'center' }}>Tomato Pay</h2>
            <Input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={handleAmountChange}
                style={{ marginBottom: '10px' }}
            />
            {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '10px' }}>
                <Button type="primary" onClick={handleConfirmClick} style={{ marginBottom: '10px', display: "none" }}>
                    Confirm Payment
                </Button>

                <Button type="primary" onClick={handleGetPayParamsClick} style={{ marginBottom: '10px', display: showConfirmButton ? "block" : "none" }}>
                    NEXT(下一步)
                </Button>

                <Button id='debug' type="primary" onClick={handleDebugClick} style={{ marginBottom: '10px', display: showPayButton ? "block" : "none" }}>
                    Pay（确认支付）
                </Button>

                <Button type="primary" onClick={jsapiClick} style={{ marginBottom: '10px', display: "none" }}>
                    JSAPI
                </Button>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                <ThreeDots
                    visible={loading}
                    height="80"
                    width="80"
                    color="#404040"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>

        </div>
    );
};

export default PaymentForm;
