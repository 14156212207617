import React from 'react';

// 封装支付检测方法
const detectPaymentMethod = () => {
  const ua = window.navigator.userAgent;

  // 判断是不是微信
  if (ua.indexOf("MicroMessenger") > 0) {
    return "WeiXin";
  }
  // 判断是不是支付宝
  else if (ua.indexOf("AlipayClient") > 0) {
    return "Alipay";
  }
  // 哪个都不是
  else {
    return "Unknown";
  }
};

class PaymentDetector extends React.Component {
  handleButtonClick = () => {
    const paymentMethod = detectPaymentMethod();

    // 在这里根据支付方式执行相应的操作，例如弹窗显示
    alert(`支付客户端：${paymentMethod}`);
  };

  render() {
    return (
      <div>
        <button onClick={this.handleButtonClick}>检测支付方式</button>
      </div>
    );
  }
}

export default PaymentDetector;